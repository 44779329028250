export var urls = {
    test: "",
    development: "auth/",
    production: "",
    auth: {
        login: "auth/jwt/create/",
        recoverPassword: "api/password_reset/",
        resetPassword: "api/password_reset/confirm/",
        logout: "",
    },
    token: {
        auth: 'auth/jwt/create/',
        refresh: 'auth/jwt/refresh/',
    },
    user: {
        profile: 'users/me/',
        wishlist: 'users/api/v1/Wishlist/',
        validateReferral: 'users/api/v1/Referral/validate/',
        myPanelViewSet: 'users/api/v1/MyPanel/me/',
    },
    teacher: {
        signUp: "users/api/v1/Teacher/",
        update: "users/api/v1/TeacherUpdate/avatar/",
        me: "users/api/v1/Teacher/0/",
        paymentOptions: 'users/api/v1/TeacherUpdatePO/payment_options/'
    },
    website: {
        sociaLinks: 'website/api/v1/SocialLink/?limit=10',
        textBlocks: 'website/api/v1/TextBlock/?limit=10',
        menus: 'website/api/v1/Menu/?limit=1',
        page: 'website/api/v1/Page/',
        contact: 'website/api/v1/Contact/',
        banner: 'website/api/v1/Banner'
    },
    questions: {
        knowledgeAreas: 'questions/api/v1/KnowledgeArea/?limit=2000',
        educationalStage: 'questions/api/v1/EducationalStage/?limit=2000',
        disciplines: 'questions/api/v1/Discipline/?limit=2000',
        source: 'questions/api/v1/Source/?limit=2000',
        cognitive_axis: 'questions/api/v1/CognitiveAxis/?limit=2000',
        educational_stage: 'questions/api/v1/EducationalStage/?limit=2000',
        competency: 'questions/api/v1/Competency/?limit=2000',
        subject: 'questions/api/v1/Subject/?limit=2000',
        topic: 'questions/api/v1/Topic/?limit=2000',
        ability: 'questions/api/v1/Ability/?limit=2000',
        originality: 'questions/api/v1/QuestionOriginality/?limit=2000',
        question: 'questions/api/v1/Question/',
        myQuestions: 'questions/api/v1/MyQuestions/',
        MyQuestionsPDF: 'questions/api/v1/MyQuestionsPDF/',
        questionUpdate: 'questions/api/v1/Question/:id/',
        QuestionItem: 'questions/api/v1/QuestionItem/:id/',
        QuestionItemView: 'questions/api/v1/QuestionItemView/:id/',
        QuestionItemCorrectorView: 'questions/api/v1/QuestionItemCorrectorView/:id/',
        QuestionCorrectorsInvites: 'questions/api/v1/QuestionCorrectorsInvites/',
        SuggestedCorrection: 'questions/api/v1/SuggestedCorrection/',
        SuggestedCorrectionAnswer: 'questions/api/v1/SuggestedCorrectionAnswer/:id/',
        QuestionItemCorrection: 'questions/api/v1/QuestionItemCorrection/:id/',
        QuestionFilterControl: 'questions/api/v1/QuestionFilterControl/',
        ShopQuestion: 'questions/api/v1/ShopQuestion/',
        QuestionDenounce: 'questions/api/v1/QuestionDenounce/',
        QuestionVote: 'questions/api/v1/QuestionVote/',
    },
    shop: {
        Order: 'shop/api/v1/Order/0/',
        OrderClose: 'shop/api/v1/Order/:id/',
        OrderItem: 'shop/api/v1/OrderItem/',
        OrderDone: 'shop/api/v1/OrderDone/:id/',
    },
    financial: {
        subscriptionPlan: 'financial/api/v1/SubscriptionPlan/?limit=10',
        creditPackage: 'financial/api/v1/CreditPackage/?limit=10',
        CreditPackageCart: 'financial/api/v1/PaymentCartCreditPackageViewSet/:id/',
        CreditPackageCartClose: 'financial/api/v1/PaymentCartCreditPackageViewSet/',
        CreditCard: 'financial/api/v1/CreditCard/',
        PaymentCart: 'financial/api/v1/PaymentCart/',
        Balance: 'financial/api/v1/Balance/report',
        CreditPackage: 'financial/api/v1/CreditPackage/?limit=10&ordering=price',
    }
};
export function apiUrl(name, args) {
    try {
        if (!args) {
            // @ts-ignore
            var part = "".concat(name)
                .split('.')
                .reduce(function (acc, part) { return acc && acc[part]; }, urls);
            return "".concat(process.env.REACT_APP_API_URL).concat(part);
        }
        else {
            // @ts-ignore
            var url = "".concat(name).split('.').reduce(function (acc, part) { return acc && acc[part]; }, urls);
            for (var arg in args) {
                // @ts-ignore
                url = url.replaceAll(":".concat(arg), args[arg]);
            }
            // @ts-ignore
            return "".concat(process.env.REACT_APP_API_URL).concat(url);
        }
    }
    catch (e) {
        console.log(e);
        return '#';
    }
}
var api = {};
export default api;
