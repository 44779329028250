import React, {createContext, useContext, useEffect, useState} from "react";
import {Redirect, Route} from "react-router-dom";
import {urlr} from "../components/app/urls";
import {AuthService} from "../services/authService";
import useSWR from "swr";
import {apiUrl} from "../services/api";
import {AUTH_TOKEN} from "../constants";
import {authedPostFetcher} from "../hooks/website/fetcher";
import __mutators__ from "../services/mutators";
import PaymentCart from "../components/pages/teacher/shop/PaymentCart";
import {SelectedProvider} from "./SelectContext";

export const AuthenticationContext = createContext(null);

export const ProtectedRoute = ({children, ...props}) => {
  const {navigationStatus, isUserLoggedIn} = useContext(AuthenticationContext);
  return isUserLoggedIn ? (
    navigationStatus === 3 ? <Route {...props}>{children}</Route> : <PaymentCart/>
  ) : (
    <Redirect to={urlr('pub.login')}/>
  )
}

const Authentication = ({children}) => {
  const [token, setToken] = useState(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [navigationStatus, setNavigationStatus] = useState(null);

  const login = (username, password) => {
    const self = this;
    return AuthService.login({username, password})
      .then(response => {
        console.log(response);
        if (response === true) {
          setIsUserLoggedIn(true);
          return true;
        }
        setIsUserLoggedIn(false);
        return false;
      })
      .then(resp => {
        if (resp) {
          checkNavigationStatus();
        }
        return resp;
      });
  }

  const checkNavigationStatus = () => {
    AuthService.getUserData()
      .then(response => {
        setNavigationStatus(response.use_status);
      });
  }

  const logout = () => {
    return AuthService.logout()
      .then(result => {
        setToken(null);
        setIsUserLoggedIn(false);
      })
  }

  const setLoggedIn = (access) => {
    setToken(access);
    setIsUserLoggedIn(true);
  }

  const {
    data,
    error,
    isValidating
  } = useSWR(`${apiUrl('token.refresh')}`, AuthService.isLoggedIn() ? AuthService.tokenRefresh : null, {
    fetcher: authedPostFetcher,
    refreshInterval: 1000 * 60 * 2, // fift minutes
    revalidateOnFocus: false
  });

  useEffect(() => {
    setIsUserLoggedIn(AuthService.isLoggedIn());
    setToken(AuthService.getToken());
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (data) {
      setLoggedIn(data.access);
    }
    if (error) {
      logout();
    }
  }, [data, error, isValidating]);

  useEffect(() => {
    window.addEventListener('storage', e => {
      if (e.key === `${AUTH_TOKEN}`) {
        setIsUserLoggedIn(!!e.newValue);
      }
    });
  });
  __mutators__['navigationStatus'] = checkNavigationStatus;
  return (
    <AuthenticationContext.Provider value={{isUserLoggedIn, checkNavigationStatus, navigationStatus, login, logout}}>
      <SelectedProvider>
        {children}
      </SelectedProvider>
    </AuthenticationContext.Provider>
  )
}

export default Authentication;
