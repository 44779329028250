import React from "react";
import IntroBar from "../../shareds/IntroBar";
import Btns from "../../shareds/Btns";
import {apiUrl} from "../../../../services/api";
import useDynFetch from "../../../../hooks/website/useDynFetch";
import {Table} from "react-bootstrap";
import {Helpers} from "../../../../utils/helpers";
import {Link} from "react-router-dom";

export default () => {

  let url = apiUrl('financial.PaymentCart');
  const {response, mutate} = useDynFetch(`${url}0/`, true);

  const getTotal = () => {
    let total = 0;
    response?.data?.items.map(item => total += parseFloat(item.amount));
    return total;
  }

  return (
    <>
      <IntroBar title="Dados da compra"/>
      <div className="container">
        <div className="row py-3">
          <div className="col-lg-12">
            <div className="cr-thanks-buy mb-3">
              <h3>Abaixo os dados da sua assinatura</h3>
              <span className="text-info">O pagamento está sendo processado. Aguarde alguns instantes.</span>
            </div>
          </div>
        </div>
        <div className="row pb-5 pt-3">
          <div className="col-lg-12 ">
            <div className="cr-wrap-tks-credit">
              <div className="cr-tks-credit">
                <Table bordered hover>
                  <thead>
                  <tr>
                    <th align="right" style={{textAlign: "right"}}>Item</th>
                    <th align="right" style={{textAlign: "right"}} className="cr-table-right">Valor</th>
                  </tr>
                  </thead>
                  <tbody>
                  {response?.data?.items.map((item, index) => {
                    return (
                      <tr key={`id-${index}-th-i-c`}>
                        <td align="right" style={{textAlign: "right"}}>{item.description}</td>
                        <td
                          className={`cr-table-right text-right${item.amount < 0 ? " text-success" : ""}`}
                          align="right">{Helpers.asMoneyFormat(item.amount)}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                  <tfoot>
                  <tr>
                    <th align="right" style={{textAlign: "right"}}>Total</th>
                    <th align="right" style={{textAlign: "right"}}>
                      <h4>{Helpers.asMoneyFormat(getTotal())}</h4>
                    </th>
                  </tr>
                  </tfoot>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
