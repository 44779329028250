import currency from "currency.js";
import moment from "moment/moment";

moment.locale('pt-br')

const Helpers = {}

Helpers.asMoneyFormat = value => {
  return currency(`${ value }`.replace('.', ','), {symbol: 'R$ ', decimal: ',', separator: '.'}).format();
}

Helpers.asFloatFormat = value => {
  return currency(`${ value }`.replace('.', ','), {symbol: '', decimal: ',', separator: '.'}).format();
}

Helpers.asNumberFormat = value => {
  return currency(`${ value }`.replace('.', ','), {symbol: '', decimal: ',', separator: '.'}).format().split(',')[0];
}

Helpers.ucFirst = (txt) => txt.charAt(0).toUpperCase() + txt.slice(1);

Helpers.asShortDateTime = date => date ? moment(date).format('DD/MM/YY HH:mm') || '' : null;

Helpers.asMonthYearUrl = date => date ? moment(date).format('YYYY-MM') || '' : null;

Helpers.asMonthYear = date => date ? moment(date).format('MM/YY') || '' : null;

Helpers.asMonthYearFull = date => date ? Helpers.ucFirst(`${ moment(date).format("MMMM/YYYY") }`) || '' : null;

Helpers.monthsFromInterval = (startDate, endDate) => {
  startDate = moment(startDate).format("YYYY-MM-DD").toString();
  endDate = moment(endDate).format("YYYY-MM-DD").toString();

  console.log(endDate);
  console.log(startDate);

  let start = startDate.split('-');
  let end = endDate.split('-');
  let startYear = parseInt(start[0]);
  let endYear = parseInt(end[0]);
  let dates = [];

  for (let i = startYear; i <= endYear; i++) {
    let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      let month = j + 1;
      let displayMonth = month < 10 ? '0' + month : month;
      dates.push(moment([i, displayMonth, '01'].join('-')).toDate());
    }
  }
  return dates;
}

Helpers.formatDifficultyLevel = value => {
  let resp = {val: currency(value, {symbol: "", decimal: '.', precision: 1}).format()};
  if (value <= 2) resp.text = "Muito Fácil";
  else if (value <= 4) resp.text = "Fácil";
  else if (value <= 6) resp.text = "Intermediário";
  else if (value <= 8) resp.text = "Difícil";
  else if (value <= 10) resp.text = "Muito Difícil";
  else resp.text = " -- "

  if (value <= 4) resp.cr = 'alta';
  else if (value <= 7) resp.cr = 'media';
  else if (value <= 10) resp.cr = 'baixa';
  else resp.cr = 'alta'

  return resp;
}

// Helpers.paserHtml = val => <JsxParser
//   allowUnknownElements={ true }
//   autoCloseVoidElements={ true }
//   renderInWrapper={ true }
//   renderUnrecognized={ true }
//   onError={ (e => {
//     console.log(e);
//     return val;
//   }) }
//   jsx={ val }
// />

Helpers.paserHtml = val => {
  if (typeof val === 'string') return <span dangerouslySetInnerHTML={ {__html: val} }/>;
  return val;
}
export {
  Helpers
}
