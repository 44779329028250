const __mutators__ = {
  update: key => {
    try {
      __mutators__[key]();
    } catch (e) {
    }
  },
  get: key => {
    try {
      return __mutators__[key]
    } catch (e) {
      return null;
    }
  }
};

export default __mutators__;