import "./Btns.scss";
import { Link } from "react-router-dom";

const Btns = ({icon, alt, myClass, href, text, onClick}) => {
  const IconBtn = icon ? <span><img src={ icon } alt={ alt }/></span> : "";
  return (
    <Link
      className={ myClass }
      to={ href || "#" }
      onClick={
        onClick ? onClick : () => {
        } }
      icon={ icon }>
      { text } { IconBtn }
    </Link>
  )
}

export default Btns;
