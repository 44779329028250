import { AuthService } from "../../services/authService";

export const fetcher = (url, options) => {

  const token = AuthService.getSecret();

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }

  if (token) {
    headers.Authorization = 'Bearer ' + token;
  }

  return fetch(url, {headers, ...options,}).then((r) => {
    if (!r.ok) {
      throw new Error("Network response was not ok");
    } else if (r?.headers?.get("content-type")?.match("json")) {
      return r.json();
    }
  });
}

export const authedPostFetcher = (url, options) => {

  const token = AuthService.getSecret();

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization": 'Bearer ' + token
  }

  return fetch(url, {headers, ...{...options, method: 'POST'},}).then((r) => {
    if (!r.ok) {
      throw new Error("Network response was not ok");
    } else if (r?.headers?.get("content-type")?.match("json")) {
      return r.json();
    }
  });
}


