import React from 'react';
import "./IntroBar.scss";

const IntroBar = (props) => {
  return (
    <div className="cr-intro-bar">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{ props.title }</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntroBar
