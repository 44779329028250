var MAIN_STUDENT_URL_PREFIX = '/aluno';
var MAIN_TEACHER_URL_PREFIX = '/professor';
var MAIN_PUBLIC_URL_PREFIX = '/';
var ROUTES = {
    pub: {
        index: MAIN_PUBLIC_URL_PREFIX,
        login: '/login',
        recoverPassword: '/recuperar-senha',
        changePassword: '/alterar-senha/:request',
        registerReferral: '/register/:referral',
        register: '/register',
        page: '/page/:slug'
    },
    stu: { index: MAIN_STUDENT_URL_PREFIX },
    tea: {
        index: "".concat(MAIN_TEACHER_URL_PREFIX),
        home: "".concat(MAIN_TEACHER_URL_PREFIX, "/home"),
        create: "".concat(MAIN_TEACHER_URL_PREFIX, "/criar-questao"),
        avatar: "".concat(MAIN_TEACHER_URL_PREFIX, "/avatar"),
        editQuestion: "".concat(MAIN_TEACHER_URL_PREFIX, "/editar-questao/:id"),
        viewQuestion: "".concat(MAIN_TEACHER_URL_PREFIX, "/visualizar-questao/:id"),
        correctQuestion: "".concat(MAIN_TEACHER_URL_PREFIX, "/corrigir-questao/:id"),
        shop: "".concat(MAIN_TEACHER_URL_PREFIX, "/comprar"),
        myQuestions: "".concat(MAIN_TEACHER_URL_PREFIX, "/minhas-questoes"),
        corrections: "".concat(MAIN_TEACHER_URL_PREFIX, "/lista-correcoes"),
        exportMyQuestions: "".concat(MAIN_TEACHER_URL_PREFIX, "/exportar-questoes"),
        packagesCart: "".concat(MAIN_TEACHER_URL_PREFIX, "/comprar-creditos/:id"),
        packages: "".concat(MAIN_TEACHER_URL_PREFIX, "/comprar-creditos"),
        cart: "".concat(MAIN_TEACHER_URL_PREFIX, "/carrinho"),
        financial: "".concat(MAIN_TEACHER_URL_PREFIX, "/painel-financeiro"),
        panel: "".concat(MAIN_TEACHER_URL_PREFIX, "/painel"),
        cartDone: "".concat(MAIN_TEACHER_URL_PREFIX, "/compra-efetuada/:id"),
        whishlist: "".concat(MAIN_TEACHER_URL_PREFIX, "/lista-de-desejos"),
    }
};
export function urlr(name, args) {
    try {
        var url = "#";
        if (!args) {
            // @ts-ignore
            return "".concat(name).split('.').reduce(function (acc, part) { return acc && acc[part]; }, ROUTES);
        }
        else {
            // @ts-ignore
            var url_1 = "".concat(name).split('.').reduce(function (acc, part) { return acc && acc[part]; }, ROUTES);
            for (var arg in args) {
                // @ts-ignore
                url_1 = url_1.replaceAll(":".concat(arg), args[arg]);
            }
            // @ts-ignore
            return url_1;
        }
    }
    catch (e) {
        return '#';
    }
}
