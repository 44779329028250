import useSWR from "swr";
import axios from "axios";
import { AuthService } from "../../services/authService";

export default (url, auth = false, args = {}, run = true) => {
  const config = {};

  if (auth) {
    const token = AuthService.getSecret()
    config.headers = {
      'Authorization': 'Bearer ' + token
    }
  }

  const {data, mutate, error} = useSWR(url, run ? async url => {
    return axios.get(url, config);
  } : null, {
    refreshInterval: 1000 * 60 * 20, // 20 minutes
    revalidateOnFocus: false
  });

  const loading = !data && !error;

  return {
    loading,
    error,
    response: data,
    mutate
  }

}