import './Loading.scss';

export default (props) => {
  return (
    <div className="loading-container">
      <div className="loading-text">
        <center>{ props.text ? props.text : "Aguarde..." }</center>
      </div>

      <div className="line">
      </div>

      <div id="pencil">
        <div className="pbody"/>
        <div id="eraser"/>
      </div>
    </div>
  )
}