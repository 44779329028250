import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import React, {lazy, Suspense} from "react";
import {urlr} from "./urls";
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../pages/shareds/Loading";
import {createBrowserHistory} from "history";
import {ToastContainer} from "react-toastify";
import Authentication from "../../context/AuthenticationContext";
import {SWRConfig} from "swr";
import {fetcher} from "../../hooks/website/fetcher";
import {TeacherProvider} from "../../context/TeacherContext";

const TeacherApp = lazy(() => import("../pages/teacher/App"));
const PublicApp = lazy(() => import("../pages/public/App"));

export default () => {
  const history = createBrowserHistory();
  return (
    <SWRConfig value={{
      ...{
        revalidateOnFocus: false,
        shouldRetryOnError: false
      }, fetcher
    }}>
      <Authentication>
        <ToastContainer/>
        <Router history={history}>
          <Suspense fallback={<Loading/>}>
            <Switch>
              <Route path={urlr('tea.index')}>
                <TeacherProvider>
                  <TeacherApp/>
                </TeacherProvider>
              </Route>
              <Route path={urlr('pub.index')}>
                <PublicApp/>
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </Authentication>
    </SWRConfig>
  )
}