import React, {useEffect, useState} from "react";
import {apiUrl} from "../services/api";
import useDynFetch from "../hooks/website/useDynFetch";

const TeacherContext = React.createContext();

const TeacherProvider = ({children}) => {
  const [teacher, setTeacher] = useState();

  const url = apiUrl('teacher.me');

  const {response, mutate, loading} = useDynFetch(url, true);

  useEffect(() => {
    if (response?.data) {
      setTeacher(response.data);
    }
  }, [loading]);

  return <TeacherContext.Provider value={{teacher: teacher}}>
    {children}
  </TeacherContext.Provider>
}

const useTeacher = () => {
  const context = React.useContext(TeacherContext);
  if (context === undefined) {
    throw new Error('useTeacher must be used within a TeacherProvider');
  }
  return context;
}

export {TeacherProvider, useTeacher}