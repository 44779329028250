import React, {useState} from "react";

const SelectedContext = React.createContext();

const SelectedProvider = ({children}) => {
  const [selected, setSelected] = useState([]);

  const value = {selected, setSelected}
  return <SelectedContext.Provider value={value}>
    {children}
  </SelectedContext.Provider>
}

const useSelected = () => {
  const context = React.useContext(SelectedContext);
  if (context === undefined) {
    throw new Error('useSelected must be used within a SelectedProvider')
  }
  return context
}

export {SelectedProvider, useSelected}