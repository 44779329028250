import React from 'react';
import { render } from 'react-dom';
import 'sweetalert2/dist/sweetalert2.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './index.scss';
import routes from "./components/app/routes";

const startup = (element) => {
  render(routes(), element);
};

startup(document.getElementById('smartweb'));
// reportWebVitals();
